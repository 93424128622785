@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

body { 
	margin: 0;
	font-family: Lato;
}

html {
  background-image: url("assets/pawel-czerwinski-qzaIDFtzcZ0-unsplash.png");
  height: 100%; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}