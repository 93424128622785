* {
  box-sizing: border-box;
  color: #000;
  font-size: 16px;
}

.main {
  background-image: none;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.App {
  margin: 0;
  padding: 0;
}

div.logo {
  content: url("assets/logo.png");
  width: 122px;
  height: 35px;
  display: table;
  margin: 100px auto 25px;
}

div.logosmall {
  content: url("assets/logo.png");
  width: 122px;
  height: 35px;
  display: table;
  margin: 0;
}

.label {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.login {
  background-color: #ffffff;
  border-radius: 25px;
  width: 900px;
  height: 600px;
  margin: 8em auto;
  border-radius: 1.5em;
  box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
}

.container {
  margin: 0 auto;
  max-width: 1000px;
  padding: 40px;
}

.container-header {
  display: table;
  min-height: 200px;
  margin: 100px auto 0;
}

.login-title {
  color: #bd6a4d;
  font-size: 50px;
}

.userinfo {
  float: right;
  margin-right: 15px;
}

.cartbadge {
  float: right;
  font-size: 10px;
  color: #000;
  margin-right: 15px;
}

div.cartbadge:after {
  content: url("assets/cart@2x.png");
  width: 50px;
  height: 35px;
  display: table;
  margin: 0;
}

.menu {
  float: right;
}

div.menu:after {
  content: url("assets/menu@2x.png");
  width: 50px;
  height: 35px;
  display: table;
  margin: 0;
}

.title {
  color: #bd6a4d;
  font-size: 30px;
  display: table;
  margin: 20px auto;
  width: 100%;
  text-align: center;
}

.search-title {
  font-size: 18px;
}

.date {
  margin-top: 12px;
  font-size: 16px;
}

.form {
  display: grid;
}

.card {
  margin-bottom: 1rem;
  display: flex;
}

.card--image {
  flex: 100%;
  margin-top: 1rem;
  border-radius: 10px;
}

.card-list {
  column-count: 3;
}

/* Add to cart */
.IconButton:hover .Iconclass {
  background-color: #000;
}

/* Style filter button */
button.btn:after {
  content: url("assets/filter@2x.png");
  width: 22px;
  height: 22px;
}

.btn {
  border: none;
  outline: none;
  cursor: pointer;
  width: 22px;
  height: 22px;
  margin: 0;
  padding: 0;
  background-color: #fff;
  float: right;
}

.btn:hover {
  background-color: #ddd;
}

.btn.active {
  background-color: #fff;
}

.bottom-section {
  margin: 20px 10px 0;
  background-color: #ebecf0;
}

.bottom-aligner {
  display: inline-block;
  height: 20px;
  vertical-align: bottom;
  width: 100%;
}

.show {
  display: block;
}
.hide {
  display: none;
}
.left {
  float: left;
}
.right {
  float: right;
}

.home-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 20px 0;
  width: 100%;
}

.home-header-wrapper h1 {
  color: #bd6a4d;
  font-size: 30px;
  margin: 0;
}

.primary-btn {
  background-color: #066f50;
  border-radius: 20px;
  font-family: Lato;
  font-weight: 700;
  font-size: 17px;
  color: white;
  padding: 7px 25px;
  outline: none;
  border: none;
  cursor: pointer;
}

.primary-btn:hover {
  background-color: #066f50;
  color: white;
}

.primary-btn.upload-plant-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 768px) {
  .form {
    grid-template-columns: auto 1fr auto;
    grid-gap: 1rem;
    align-items: center;
  }
  .input {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 600px) {
  .card-list {
    column-count: 1;
  }
}

.footer {
  padding: 20px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.m-0 {
  margin: 0 !important;
}


/* Request Photo Form */
.request-photo-form {
  padding: 10px;
  margin: 0 auto;
  width: 50% ;
}

.request-photo-form .open-close{
  margin-top: 20px;
  margin-left: 20px;
  padding-top: 10px;
  width: 20px;
  height: 20px;
  font-size: 30px;
  display: inline-block;
}

.request-photo-form h3 {
  text-align: center;
  cursor: pointer;

}

.request-photo-form .form-header h6 {
  color: #fff;
  margin: 0;
  padding: 10px;
  background-color: #066F50;
  border-radius: 4px 4px 0 0;
}

.request-photo-form h3 {
  text-align: center;
  margin-bottom: 20px;
}

.request-photo-form .form-group {
  margin-bottom: 15px;
}

/* .request-photo-form input, select {
  width: 100%;
  padding: 8px;
  display: inline-block;
  box-sizing: border-box;
} */

.request-photo-form .button-submit {
  width: 100%;
  background-color:#066F50;
  color: white;
  padding: 10px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 28px;
  cursor: pointer;
}

.request-photo-form button:hover {
  background-color: #45a049;
}
