body {
  background-color: #f2f3f5;
  font-family: 'lato';
}

.user-text {
  font-weight: normal;
  color: black;
  margin: 10px;
  font-family: 'lato';
}

.side-by-side {
  display: flex;
  flex-direction: row;
}

.admin-tab {
  font-size: 23px !important;
  text-transform: capitalize !important;
  background-color: #f6f6f6 !important;
  box-shadow: 2px 0px 16px rgba(175, 175, 175, 0.05);
  padding: 15px 55px !important;
  font-family: 'lato';
}

.admin-tab[aria-selected="true"] {
  color: #bd6a4d !important;
  background-color: white !important;
  box-shadow: 0px -6px 30px rgba(150, 150, 150, 0.2);
  font-family: 'lato';
}

.admin-plants-container {
  background-color: white;
  font-family: 'lato';
}

.admin-plant-row {
  padding: 22px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-width: 0px;
  border-color: #d6dae2;
  border-style: solid;
  font-family: 'lato';
}

.admin-plant-row span {
  font-weight: 400;
  font-size: 15px;
  font-family: 'lato';
}

.admin-plant-row h2 {
  font-size: 22px;
  font-weight: 500;
  margin: 0;
  font-family: 'lato';
}

.admin-plant-row[data-type="heading"] {
  border-top-width: 1px;
  font-family: 'lato';
}

.admin-plant-row-actions {
  display: flex;
  align-items: center;
}

.admin-plant-row-actions img {
  margin: 0 8px;
  cursor: pointer;
}

.admin-plant-row[data-type="add-new"] {
  justify-content: flex-start;
  cursor: pointer;
  font-family: 'lato';
}

.admin-plant-row[data-type="add-new"] span {
  font-size: 20px;
  font-weight: 600;
  color: #0075ff;
  margin-left: 12px;
  text-transform: capitalize;
  font-family: 'lato';
}

.admin-plants-pagination {
  justify-content: flex-start;
  font-family: 'lato';
}

.admin-plants-pagination-dot {
  font-size: 20px;
  font-weight: 500;
  display: block;
  margin: 0 5px;
  line-height: 2em;
  font-family: 'lato';
}

.admin-plants-pagination button {
  padding: 5px 10px;
  background-color: white;
  border-color: #d6dae2;
  min-width: 50.7px;
  font-family: 'lato';
}

.admin-plants-pagination button.admin-plants-pagination-active {
  background-color: #bd6a4d;
  color: white;
  font-family: 'lato';
}
